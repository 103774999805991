export const html: string = `
<div class="flex-left column spacing-xl no-wrap space-between full-width full-height">
    <div class="flex-left column spacing-sm stretch no-wrap full-width">
        <div class="flex-left">
            <button class="btn-icon" type="button" @click="back()">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 18L9 12L15 6" stroke="var(--icon-strong, #98A2B3)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
        </div>
        <fieldset class="input-group">
            <legend class="h4">Design</legend>
            <div class="option-cards">
                <label class="flex-left no-wrap centered" v-if="!productService.base.disable_upload && !productService.base.design_only">
                    <input type="radio" value="upload" name="design_option" v-model="selected_action">
                    <span class="flex-left full-width column spacing-xxs inline">
                        Upload a file
                        <span class="text-sm">(eps, ai, pdf, psd, tif, png, jpg, heif or avif)</span>
                    </span>
                </label>
                <label class="flex-left no-wrap centered" v-if="!productService.base.disable_upload && files && !productService.base.design_only">
                    <input type="radio" value="previous-file" name="design_option" v-model="selected_action">
                    <span class="flex-left full-width column spacing-xxs inline">
                        Use a previous file
                        <span class="text-sm">Select a file you’ve uploaded before</span>
                    </span>
                </label>
                <label class="flex-left no-wrap centered" v-if="!productService.base.disable_design">
                    <input type="radio" value="new-design" name="design_option" v-model="selected_action">
                    <span class="flex-left full-width column spacing-xxs inline">
                        Build a new design
                        <span class="text-sm">Create a design using our online tool</span>
                    </span>
                </label>
                <label class="flex-left no-wrap centered" v-if="!productService.base.disable_design && autosaves">
                    <input type="radio" value="existing-design" name="design_option" v-model="selected_action">
                    <span class="flex-left full-width column spacing-xxs inline">
                        Load an existing design
                        <span class="text-sm">Continue working on a saved design</span>
                    </span>
                </label>
            </div>
        </fieldset>
    </div>
    <div class="flex-left column no-wrap full-width">
        <message-list :errors="productService.errors" field="__all__">
            <button type="button" class="btn-base btn-primary-action full-width btn-large" @click="nextStep();" :disabled="productService.saving || !selected_action">{{ productService.saving ? 'Saving' : 'Continue'  }}</button>
        </message-list>
    </div>
</div>
`;