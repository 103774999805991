import VueComponent, {data, method} from '../../../../core/adapters/VueComponent';
import {html} from './ProductProductionTime.html';
import {Services} from '../../../../core/services/Services';
import {Http} from '../../../../core/services/Http';

class ProductProductionTimeController extends VueComponent {

    @data()
    standard: number;

    @data()
    rush: number;

    @data()
    message: string;

    @data()
    loaded: boolean;

    @data()
    backordered: boolean;

    // Do not store the product service in the data. There is some odd bug
    // that bricks the component and prevents it from updating the html.
    // @data()
    // product_service;

    constructor(componet) {
        super(componet);

        Services.waitOn('product-service-loaded').then(() => {
            let product_service: any = Services.get('ProductService');
            product_service.configured.bind('change:material', this.updateTimes.bind(this));
            product_service.configured.bind('change:quantity', this.updateTimes.bind(this));

            this.backordered = product_service.configured.material.backordered;
            product_service.configured.bind('change:material', () => {
                this.backordered = product_service.configured.material.backordered;
            })
            this.updateTimes();
        });
    }

    @method()
    updateTimes() {
        let product_service: any = Services.get('ProductService');
        if (!product_service.configured.material || !product_service.configured.quantity) {
            return;
        }

        Services.get<Http>('$http').request({
            method: 'GET',
            url: '/delivery/api/get-production-times-basic/',
            params: {
                material: product_service.configured.material.id,
                quantity: product_service.configured.quantity
            }
        }).then((response) => {
            this.message = response.data.message;
            this.standard = response.data.standard;
            this.rush = response.data.rush;
            this.$forceUpdate();
        });
    }
}

export default function ProductProductionTime() {
    return {
        tag: 'product-production-time',
        controller: ProductProductionTimeController,
        template: html
    }
}