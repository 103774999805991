import VueComponent, {data, method, prop} from '../../../../../core/adapters/VueComponent';
import IVueComponent from '../../../../../core/adapters/IVueComponent';
import {html} from './SelectFile.html';
import CustomService from '../../../services/CustomService';
import {UserFile} from '../../../models/UserFile';
import {ToolTypeEnum} from '../../../models/enums';
import APIResponse from '../../../../../core/models/APIResponse';
import {Services} from '../../../../../core/services/Services';

class SelectFileController extends VueComponent {

    @prop({
        emit: true
    })
    step: number;

    @prop()
    productService: CustomService;

    @data()
    user_files: APIResponse<UserFile>;

    @data()
    limit: number = 16;

    @data()
    page: number = 1;

    @data()
    search: string;

    constructor(component) {
        super(component);
        this.filter(this.page);
    }

    @method()
    back() {
        this.step = this.step - 1;
    }

    @method()
    selectUserFile(user_file) {
        this.productService.configured.tool_type = ToolTypeEnum.UPLOAD;
        this.productService.configured.user_file = user_file;
        this.goToFinalize();
    }

    @method()
    goToFinalize() {
        this.productService.configured.tool_type = ToolTypeEnum.UPLOAD;

        if (this.productService.base.auto_background_removal) {
            this.productService.remove_background = true;
        }

        this.productService.save();
    }

    @method()
    filter(page?) {
        if (page) {
            this.page = page;
        }
        this.user_files = Services.get<typeof UserFile>('UserFile').objects.filter({
            limit: this.limit,
            offset: this.limit * (this.page - 1),
            name__icontains: this.search,
            hidden: false
        });
    }

    @method()
    removeUserFile(file: UserFile) {
        file.remove().then(() => {
            this.user_files.items.removeItemFromID(file.id);
            this.filter();
        });
    }
}

export default function SelectFile(): IVueComponent {
    return {
        template: html,
        controller: SelectFileController,
        tag: 'setup-select-file'
    }
}