/*
    Custom.ts

    This file contains everything that's needed to load the setup page
 */

import {Services} from '../../ts/core/services/Services';

import registerCustomServices from '../../ts/apps/svgcustom/services/setup.init';
import registerCoreComponents from '../../ts/core/components/init';

import CustomProductSetupPageApp from '../../ts/apps/svgcustom/apps/CustomProductSetupPageApp';
import registerProductAddonServices from '../../ts/apps/products_shared/services/init';
import {IntegrationExtra} from '../../ts/core/utils/IntegrationExtra';
import registerSetupComponents from '../../ts/apps/svgcustom/components/SetupPage/init';
import FinalizePageApp from '../../ts/apps/svgcustom/apps/FinalizePageApp';
import TemplateFinalizeOptions from '../../ts/apps/svgcustom/components/TemplateFinalizeOptions/Options';
import registerPackagingComponents from '../../ts/apps/packaging/components/init';


export default class SetupIntegration extends IntegrationExtra {
    override configureServices() {
        Services.resolve('loaded-page-app');
    }

    setupApps(): void {
        FinalizePageApp.mount('#finalize-app', (app) => {
            registerCoreComponents(app);
            registerSetupComponents(app);
            app.registerComponent(TemplateFinalizeOptions);
            registerPackagingComponents(app);
        });

        CustomProductSetupPageApp.mount('#setup-app', (app) => {
            registerCoreComponents(app);
            registerSetupComponents(app);
        });
    }

    setupModels(): void {
        // Done in CustomCommon.ts
    }

    setupServices(): void {
        registerProductAddonServices(Services);
        registerCustomServices(Services);
    }
}