import {EventDispatcher} from 'simple-ts-event-dispatcher';
import MessageList from '../../../core/utils/MessageList';
import {Services} from '../../../core/services/Services';
import UserProfileService from '../../userprofile/services/UserProfileService';
import PricingTier from '../../../core/utils/PricingTier';
import {ProductAttributeAbstract} from '../../product/models/abstract/ProductAttributeAbstract';
import {Model} from '../../../core/models/Model';
import {IDeferred, IPromise, SimplePromise} from '../../../core/utils/SimplePromise';
import {Analytics} from '../../../core/services/Analytics';
import {all} from 'axios';
import {FontService} from '../../svgcustom/services/FontService';

export default abstract class ProductServiceAbstract extends EventDispatcher {
    public base: ProductAttributeAbstract;
    public configured: Model;

    public base_class: typeof ProductAttributeAbstract;
    public configured_class: typeof Model;
    public base_key: string; // The key to access the base object on the configured class, ex: template, clipart_product

    public errors: MessageList;
    public warnings: MessageList;

    public setup_complete: IPromise<void>;
    protected setup_complete_deferred: IDeferred<void>;

    public ups: UserProfileService;

    public saving: boolean;
    public auto_page_transition: boolean;
    public add_to_cart: boolean;

    public page_change_params;

    currency: string;

    protected show_retail_value: boolean = false;

    constructor(base_class, configured_class, base_key) {
        super();

        this.setup_complete_deferred = SimplePromise.defer<void>();
        this.setup_complete = this.setup_complete_deferred.promise;

        this.errors = new MessageList();
        this.warnings = new MessageList();
        this.ups = Services.get<UserProfileService>('UserProfileService');

        this.base_class = base_class;
        this.configured_class = configured_class;
        this.base_key = base_key;
        this.auto_page_transition = true;
        this.add_to_cart = true;

        this.setup_complete.then(() => {
            if (this.price.price == 0) {
                this.once('change:price', () => {
                    Services.get<Analytics>('Analytics').trackProductPage(this.base.sku, this.price.price);
                });
            }
            else {
                Services.get<Analytics>('Analytics').trackProductPage(this.base.sku, this.price.price);
            }
        });

        window.addEventListener('pageshow', (event) => {
            // The page was loaded from cache, reset any states that prevent the user from using the page
            if (event.persisted) {
                this.saving = false;
                this.trigger('sync');
            }
        });
    }

    setupFromData(base_data, configured_data, all_data): void {
        let base = null;
        let configured = null;

        if (base_data) {
            base = new this.base_class(base_data);
        }
        if (configured_data) {
            configured = new this.configured_class(configured_data);
        }

        if (!base && configured) {
            base = configured[this.base_key];
        }
        if (!configured) {
            configured = new this.configured_class();
        }

        this.currency = all_data.currency;

        this.setupExtraData(all_data);
        this.setupFromProduct(base, configured);
    }

    setupExtraData(all_data): void {}

    setupFromProduct(base, configured): void {
        this.base = base;
        this.configured = configured;

        this.setupConfiguredFromBase();
    }

    setupConfiguredFromBase(needs_additional_setup=false): void {}

    abstract save($event?): void;

    // Available hooks for saving
    onSaveSuccess(response): void {}
    onSaveFailure(response): void {
        this.page_change_params = null;
    }
    async preSave() {};

    nextUrl() {
        return '/cart/'
    }

    pageTransition(response): boolean {
        if (this.auto_page_transition) {
            const next_url = new URL(window.location.origin + this.nextUrl());
            const current_url = new URL(window.location.href);

            if (current_url.searchParams.get('q')) {
                next_url.searchParams.append('q', current_url.searchParams.get('q'));
            }

            if (this.page_change_params) {
                for (const key of Object.keys(this.page_change_params)) {
                    next_url.searchParams.append(key, this.page_change_params[key]);
                }
            }

            Services.get<Analytics>('Analytics').trackAddedProductToCart(this.base.sku, this.price.price, this.quantity);

            this.onSaveSuccess(response);

            window.location.assign(next_url.href);

            return true
        }

        return false;
    }

    preValidate() {
        return;
    }

    validate(): boolean {
        if (!this.errors) {
            this.errors = new MessageList();
        }
        if (!this.warnings) {
            this.warnings = new MessageList();
        }

        this.preValidate();

        this.errors.reset();
        this.warnings.reset();
        this.configured.validate();

        this.trigger('validate', this.errors);

        this.errors.merge(this.configured.errors.list);

        return !this.hasErrors;
    }

    checkWarnings() {
        if (!this.warnings) {
            this.warnings = new MessageList();
        }

        this.warnings.reset();
    }

    get hasErrors(): boolean {
        return this.errors.length > 0;
    }

    abstract price: PricingTier;
    abstract quantity: number;

    get optionsPrice(): number {
        return 0;
    }

    get optionsUnitValue(): number {
        return 0;
    }

    get show_retail() {
        return this.show_retail_value;
    }
    set show_retail(v) {
        this.show_retail_value = v;
        this.updatePricing();
    }

    abstract updatePricing();
}
