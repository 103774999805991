export const html:string = `
<div class="flex-left column spacing-xl no-wrap space-between full-width full-height">
    <div class="flex-left column spacing-sm stretch no-wrap full-width">
        <div class="flex-left">
            <button class="btn-icon" type="button" @click="back()">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 18L9 12L15 6" stroke="var(--icon-strong, #98A2B3)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
        </div>
        <h4>Select template</h4>
        <div class="grid">
            <div class="xs-6 card padded-card-xs shadow-sm flex-center space-between column" v-for="template in productService.similar_templates.items">
                <img :src="template.thumbnail_url" :alt="template.name">
                <div class="flex-center column full-width align-center">
                    <span class="text-sm">{{ template.name }}</span>
                    <button type="button" class="btn-base btn-secondary-action full-width" @click="selectTemplate(template)">Use design</button>
                </div>
            </div>
        </div>
    </div>
</div>
`;