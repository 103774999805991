import VueComponent, {method, prop} from '../../../../../core/adapters/VueComponent';
import IVueComponent from '../../../../../core/adapters/IVueComponent';
import {html} from './SelectTemplate.html';
import CustomService from '../../../services/CustomService';

class SelectTemplateController extends VueComponent {
    @prop({
        emit: true
    })
    step: number;

    @prop()
    productService: CustomService;

    @method()
    back() {
        this.step = this.step - 1;
    }

    @method()
    selectTemplate(template) {
        this.productService.useDesign(template);
    }
}

export default function SelectTemplate(): IVueComponent {
    return {
        controller: SelectTemplateController,
        tag: 'setup-select-template',
        template: html,
    }
}