import VueComponent, {data, method, prop} from '../../../../../core/adapters/VueComponent';
import IVueComponent from '../../../../../core/adapters/IVueComponent';
import {html} from './UploadFile.html';
import CustomService from '../../../services/CustomService';
import {UserFile} from '../../../models/UserFile';
import {ToolTypeEnum} from '../../../models/enums';
import {processRequestError} from '../../../../../core/utils/utils';

class UploadFileController extends VueComponent {

    @prop({
        emit: true
    })
    step: number;

    @prop()
    productService: CustomService;

    @data()
    upload_success_callback;

    @data()
    upload_failure_callback;

    constructor(component) {
        super(component);

        this.upload_success_callback = this.uploadSuccess.bind(this);
        this.upload_failure_callback = this.uploadFailure.bind(this);
    }

    @method()
    back() {
        this.step = this.step - 1;
    }

    async uploadSuccess(response: any) {
        const user_file = UserFile.objects.get({id: response.data.user_file});
        await user_file.$promise;

        this.productService.configured.tool_type = ToolTypeEnum.UPLOAD;
        this.productService.configured.user_file = user_file;
        if (this.productService.base.auto_background_removal) {
            this.productService.remove_background = true;
        }
        this.productService.save();
    }

    uploadFailure(error) {
        if (!error || !error.response) {
            return;
        }

        this.productService.errors = processRequestError(error);
        if (error.response.data) {
            if (error.response.data.errors) {
                this.productService.errors = processRequestError(error);
            }
            if (error.response.data.error) {
                this.productService.errors.add('file', error.response.data.error);
            }
        }
    }
}

export default function UploadFile(): IVueComponent {
    return {
        template: html,
        controller: UploadFileController,
        tag: 'setup-upload-file'
    }
}