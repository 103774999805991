export const html: string = `
<div class="flex-left column spacing-md no-wrap space-between full-width full-height">
    <div class="flex-left column spacing-sm stretch no-wrap full-width">
        <div class="flex-left">
            <button class="btn-icon" type="button" @click="back()">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 18L9 12L15 6" stroke="var(--icon-strong, #98A2B3)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
        </div>
        
        <h4>Saved designs</h4>   
        <div class="spacing-none scrollbar inset-section" style="max-height: 485px; overflow-y: auto;">
            <template v-for="(item, $index) in autosaves.items">
                <div class="flex-left no-wrap full-width stretch space-between spacing-sm inset-item inset-hover">
                    <button type="button" class="btn-clear flex-left-center no-wrap spacing-sm full-width btn-square-sm" @click="selectAutosave(item)">
                        <div class="card background no-overflow flex-full-center no-shrink" style="width: 80px; height: 80px; padding: 4px">
                            <img :src="item.preview" :alt="item.name" class="full-width full-height"/>
                        </div>
                        <span class="align-left full-width text-wrap text-sm">{{ item.name || $filters.date(item.created) }}</span>
                    </button>
                    <button type="button" class="btn-icon no-shrink flex-full-center" @click.prevent="removeAutosave(item)">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17 7L7 17M7 7L17 17" stroke="var(--icon-strong, #98A2B3)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                </div>
                <hr class="padding-none" v-if="$index != autosaves.items.length - 1">
            </template>
        </div>
    </div>
    <div class="flex-center full-width">
        <callback-paginator :total="autosaves.totalCount" :limit="limit" v-model:page="page" :small="true" :callback="filter"></callback-paginator>
    </div> 
</div>
`;