export const html:string = `
<div class="flex-left no-wrap spacing-sm full-width" :data-package-id="selectedPackage.package.id">
    <canvas class="retail-package"></canvas>
    
    <div class="flex-left column spacing-xs">
        <div class="flex-left-center space-between spacing-xs no-wrap full-width">
            <b>{{ selectedPackage ? selectedPackage.package.name : 'None' }}</b>
            <b v-if="selectedPackage && selectedPackage.currency">+{{ $filters.currency(selectedPackage.priceFor(quantity).unitPrice, selectedPackage.currency) }} each</b>
            <b v-if="!selectedPackage">Free</b>
        </div>
        <span class="text-xs align-left" v-if="selectedPackage && selectedPackage.package.description">
            {{ selectedPackage.package.description }}
        </span>
    </div>
</div>`;
