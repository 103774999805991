import {html} from './QuantityBreaks.html';
import VueComponent, {computed, data, method, prop} from '../../../../core/adapters/VueComponent';
import IVueComponent from '../../../../core/adapters/IVueComponent';
import ConfiguredProductServiceAbstract from '../../services/ConfiguredProductServiceAbstract';

export class QuantityOptionsController extends VueComponent {
    @prop()
    productService: ConfiguredProductServiceAbstract;

    @data()
    quantity_options: any[];

    @data()
    show_more: boolean;

    @data()
    show_all: boolean;

    constructor(component) {
        super(component);

        if (this.productService) {
            this.productService.bind('change:price', () => {
                this.quantity_options = this.calcQuantityOptions();
            });
            this.productService.setup_complete.then(() => {
                this.quantity_options = this.calcQuantityOptions();
            })
        }
        else {
            console.error('Undefined product service');
        }
        this.updateQuantityOptions();
    }

    @method()
    updateQuantityOptions() {
        this.quantity_options = this.calcQuantityOptions();
    }

    calcQuantityOptions(): any[] {
        if (!this.productService || !this.productService.pricing) {
            return [];
        }

        let pricing = this.productService.pricing;

        // Remove the quantities that are lower than the min quantity and alter lower bracket to be the min
        let to_pop = [];
        for (let i = 0; i < pricing.length-1; i++) {
            let current = pricing[i];
            let next = pricing[i+1];

            if (current.quantity <= this.productService.base.minimum_quantity) {
                if (next.quantity <= this.productService.base.minimum_quantity) {
                    to_pop.push(current);
                }
                else {
                    current.quantity = this.productService.base.minimum_quantity;
                }
            }
        }

        for (const item of to_pop) {
            pricing.splice(pricing.indexOf(item), 1);
        }

        this.show_more = pricing.length > 3;

        if (!this.show_all && pricing.length > 3) {
            let index = 0;
            for (const item of pricing) {
                if (this.productService.base.default_quantity >= item.quantity) {
                    index++;
                }
            }

            if (index < 2) {
                index = 2;
            }

            return pricing.slice(index - 2, index + 1)
        }

        return pricing;
    }
}

export default function QuantityBreaks(): IVueComponent {
    return {
        controller: QuantityOptionsController,
        template: html,
        tag: 'quantity-breaks'
    };
}