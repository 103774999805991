import VueComponent, {data, method, prop} from '../../../../../core/adapters/VueComponent';
import IVueComponent from '../../../../../core/adapters/IVueComponent';
import CustomService from '../../../services/CustomService';
import {Services} from '../../../../../core/services/Services';
import CustomAutosave from '../../../models/CustomAutosave';
import APIResponse from '../../../../../core/models/APIResponse';
import {html} from './SelectAutosave.html';
import * as url from 'url';


class SelectAutosaveController extends VueComponent {

    @prop({
        emit: true
    })
    step: number;

    @prop()
    productService: CustomService;

    @data()
    autosaves: APIResponse<CustomAutosave>;

    @data()
    limit: number = 16;

    @data()
    page: number = 1;

    constructor(component) {
        super(component);
        this.filter();
    }

    @method()
    back() {
        this.step = this.step - 1;
    }

    @method()
    filter() {
        this.autosaves = Services.get<typeof CustomAutosave>('CustomAutosave').objects.filter({
            template: this.productService.base.id,
            no_data: true,
            limit: this.limit,
            offset: this.limit * (this.page - 1),
        });
    }

    @method()
    selectAutosave(autosave: CustomAutosave) {
        this.productService.page_change_params = {
            load_save: autosave.id
        }
        let url = new URL(window.location.origin + this.productService.base.tool_url);
        url.searchParams.set('load_save', `${autosave.id}`);
        window.location.href = url.toString();
    }

    @method()
    removeAutosave(autosave: CustomAutosave) {
        this.autosaves.items.removeItemFromID(autosave.id);
        autosave.delete();
    }
}

export default function SelectAutosave(): IVueComponent {
    return {
        template: html,
        controller: SelectAutosaveController,
        tag: 'setup-select-autosave'
    }
}