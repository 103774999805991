import VueComponent, {data, method, prop} from '../../../../../core/adapters/VueComponent';
import IVueComponent from '../../../../../core/adapters/IVueComponent';
import {html} from './SelectDesign.html';
import APIResponse from '../../../../../core/models/APIResponse';
import {UserFile} from '../../../models/UserFile';
import {Services} from '../../../../../core/services/Services';
import CustomService from '../../../services/CustomService';

class SelectDesignController extends VueComponent {

    @prop({
        emit: true
    })
    step: number;

    @prop({
        emit: true
    })
    action: string;

    @prop()
    files: boolean;

    @prop()
    reorders: boolean;

    @prop()
    autosaves: boolean;

    @prop()
    productService: CustomService;

    @data()
    selected_action: string;

    override mounted() {
        super.mounted();

        this.selected_action = 'upload';
        this.productService.setup_complete.then(() => {
            if (this.productService.base.disable_upload) {
                this.selected_action = 'new-design';
            }
            else {
                this.selected_action = 'upload';
            }
        })
    }

    @method()
    back() {
        this.step = this.step - 1;
    }

    @method()
    nextStep() {
        if (this.selected_action == 'new-design') {
            this.productService.configured.configure = true;
            this.productService.save();
        }
        else {
            this.action = this.selected_action;
            this.step = this.step + 1;
        }
    }
}

export default function SelectDesign(): IVueComponent {
    return {
        template: html,
        controller: SelectDesignController,
        tag: 'setup-select-design'
    }
}