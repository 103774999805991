export const html: string = `
<div class="flex-left column spacing-xl no-wrap space-between full-width full-height">
    <div class="flex-left column spacing-lg stretch no-wrap full-width">
        <div class="flex-left">
            <button class="btn-icon" type="button" @click="back()">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 18L9 12L15 6" stroke="var(--icon-strong, #98A2B3)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
        </div>
        <file-upload url="/custom/api/v1/upload/" :success="upload_success_callback" :failure="upload_failure_callback"></file-upload>
    </div>
</div>
`;