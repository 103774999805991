import VueApp from '../../../core/adapters/VueApp';
import {data, method, prop} from '../../../core/adapters/VueComponent';
import ProductServiceAbstract from '../../products_shared/services/ProductServiceAbstract';
import {Services} from '../../../core/services/Services';
import UserProfileService from '../../userprofile/services/UserProfileService';

export default class ProductPageLoaderAbstract extends VueApp {
    protected base_data_key: string;
    protected configured_data_key: string;
    protected product_data: any;

    @prop()
    defaultPrice: string;

    @data()
    product_service: ProductServiceAbstract;

    @data()
    image_display: number;

    @data()
    ups: UserProfileService;

    constructor() {
        super();

        this.ups = Services.get<UserProfileService>('UserProfileService');

        Services.waitOn('product-service-loaded').then(() => {
            this.product_service = Services.get<ProductServiceAbstract>('ProductService');
        })
    }

    static override init(product_service: ProductServiceAbstract, base_data_key = 'product', configured_data_key = 'configured_product') {
        super.init();

        let product_data = null;
        let product_data_element = document.getElementById("product-data");
        if (product_data_element && product_data_element.textContent) {
            product_data = JSON.parse(atob(product_data_element.textContent.replace(/(\r\n\t|\n|\r\t|\s)/gm, '')));
            product_data_element.remove()
        }

        if (!product_data) {
            return;
        }

        product_service.setupFromData(product_data[base_data_key], product_data[configured_data_key], product_data);

        Services.registerObject('ProductService', product_service);
        Services.resolve('product-service-loaded');
    }

    @method()
    scrollToTestimonials($event) {
        document.getElementById('featured-testimonial-component').scrollIntoView({behavior: 'smooth', block: 'start'});
    }

    withinViewport() {
        let el = document.querySelector('.product-options');
        if (!el) {
            return true;
        }
        return el.getBoundingClientRect().y >= 0;
    }

    @method()
    checkScrollPosition() {
        // Safari has issues with the scroll position changing.
        // In chrome and firefox, it will keep the same relative scroll position, so when the page scroll height changes, you are still viewing the same thing.
        // Safari keeps the same absolute scroll position so when the scroll height changes, the viewport jumps to some random thing on the screen...
        setTimeout(() => {
            if (!this.withinViewport()) {
                document.querySelector('.product-options')?.scrollIntoView({behavior: 'smooth', block: 'start'});
            }
        })
    }
}

