export const html:string = `
<div class="input-group">
    <label>Retail Packaging</label>
    <div v-if="!(productService.available_packages.length > 0) && productService.adjustedPackageSize()" class="text-sm">
        Size too large for packaging. <button class="btn-link" @click="productService.adjustSizeToPackage()">
            Adjust to 
            <size :value="productService.adjustedPackageSize().width"></size>
            x
            <size :value="productService.adjustedPackageSize().height"></size>
            </button>
    </div>
    <div class="flex-left-center spacing-sm">
        <input class="input-toggle" type="checkbox" v-model="show_retail_packaging" @change="toggleRetailPackaging()" :disabled="!(productService.available_packages.length > 0)">
        <span @click="manualToggle()" :style="productService.available_packages.length > 0 ? 'cursor: pointer' : 'cursor: not-allowed'">Add Retail Packaging To This Item</span>
    </div>
</div>

<fieldset class="input-group single-option" v-if="show_retail_packaging && productService.available_packages.length > 0">
    <legend>Package Type</legend>
    <div class="toggle-items flex-left column spacing-xs no-wrap">
        <label class="flex-left" :for="configured_package.package.name" v-for="configured_package in productService.available_packages" @click="updatePackage(configured_package)">
            <input id="configured_package.package.name" :value="configured_package.package.id" name="package-option" type="radio" v-model="selected_package">
            <package-preview :selected-package="configured_package"
                         :product-design="productService.product_preview"
                         :product-width="productService.width"
                         :product-height="productService.height"
                         :quantity="productService.configured.quantity"
                         :single-color="productService.base.type == 0"
                         :material-color="productService.configured.material_color ? productService.configured.material_color.name : ''">
            </package-preview>
        </label>
    </div>
</fieldset>
`;