import ProductPageLoaderAbstract from './ProductPageLoaderAbstract';
import {data} from '../../../core/adapters/VueComponent';
import {Services} from '../../../core/services/Services';
import CustomService from '../services/CustomService';

export default class CustomProductSetupPageApp extends ProductPageLoaderAbstract {

    @data()
    override product_service: CustomService;

    @data()
    show_preview_tool: boolean;

    @data()
    step: number = 0;

    @data()
    action: string;

    static override init() {
        super.init(Services.get('CustomService'), 'product', 'configured_product');
    }

    override mounted() {
        super.mounted();
    }
}