export const html:string = `
<div class="pill pill-rounded pill-lg" :class="{'pill-green': !backordered, 'pill-warning': backordered}">
    <span v-if="message">
        {{ message }}
    </span>
    <span v-if="backordered">
        Out of stock
    </span>
    <span v-if="!backordered && !message && rush != standard">
        Ships in {{ rush }} to {{ standard }} days
    </span>
    <span v-if="!backordered && !message && rush == standard">
        Ships within {{ standard }} days
    </span>
</div>
`;