import VueComponent, {data, method, prop} from '../../../../core/adapters/VueComponent';
import IVueComponent from '../../../../core/adapters/IVueComponent';
import {html} from './RetailPackageOption.html';
import ConfiguredRetailPackage from '../../../packaging/models/ConfiguredRetailPackage';

class RetailPackageOptionController extends VueComponent {
    @prop()
    productService;

    @data()
    show_retail_packaging: boolean = false;

    @data()
    selected_package;

    @method()
    manualToggle() {
        if (this.productService.available_packages.length > 0) {
            this.show_retail_packaging = !this.show_retail_packaging;
            this.toggleRetailPackaging()
        }
    }

    @method()
    toggleRetailPackaging() {
        if (!this.show_retail_packaging) {
            this.productService.configured.retail_package = null;
            this.selected_package = null
        }
        this.updatePackage(this.productService.available_packages[0]);
    }

    @method()
    updatePackage(selected: ConfiguredRetailPackage) {
        this.selected_package = selected.package.id;
        this.productService.configured.retail_package = selected;
    }
}

export default function RetailPackageOption(): IVueComponent {
    return {
        tag: 'retail-package-option',
        controller: RetailPackageOptionController,
        template: html
    }
}