import {html} from './RetailPackage.html';
import VueComponent, {data, method, prop} from '../../../../core/adapters/VueComponent';
import IVueComponent from '../../../../core/adapters/IVueComponent';
import {IDeferred, IPromise, SimplePromise} from '../../../../core/utils/SimplePromise';
import {fitInArea, getInnerHeight, getInnerWidth} from '../../../../core/utils/utils';
import {RetailPackage} from '../../models/RetailPackage';
import {PackageSize} from '../../models/PackageSize';
import ConfiguredRetailPackage from '../../models/ConfiguredRetailPackage';
import {disableVueForImport} from '../../../../core/adapters/VueUtils';

class RetailPackageDisplayController extends VueComponent {
    paper_import;
    paper;
    configured_deferred: IDeferred<void>;
    configured: IPromise<void>;
    paperScope: paper.PaperScope;
    canvas;
    project: paper.Project;
    layer: paper.Layer;
    canvas_width: number;
    canvas_height: number;
    package_raster: paper.Raster;
    product_raster: paper.Raster;
    transfer_layer: paper.Layer;
    transfer_background: paper.Raster;

    white_transfer_background = '/static/img/packaging/transfer-bg-white.jpg';
    blue_transfer_background = '/static/img/packaging/transfer-bg-blue.jpg';

    @prop({
        watch: true
    })
    productDesign: string;

    @prop({
        watch: true
    })
    selectedPackage: ConfiguredRetailPackage;

    @prop({
        watch: true
    })
    productWidth: number;

    @prop({
        watch: true
    })
    productHeight: number;

    @prop()
    singleColor: boolean;

    @prop()
    noName: boolean;

    @prop()
    quantity: number;

    @prop()
    materialColor: any;

    @data()
    reversed: boolean;

    timeout;

    constructor(component) {
        super(component);

        this.paper_import = import('paper');
        this.paper_import.then((paper) => {
            disableVueForImport(paper);
            this.paper = paper
        });

        this.reset();
        this.bind('change:selectedPackage', () => {
            this.reversed = false;
            this.startReset();
        });
        this.bind('change:productDesign', this.startReset.bind(this));
        this.bind('change:productWidth', this.startReset.bind(this));
        this.bind('change:productHeight', this.startReset.bind(this));

        if (this.selectedPackage) {
            this.selectedPackage.bind('sync', () => {
                this.trigger('sync');
            })
        }
    }

    @method()
    flip() {
        this.reversed = !this.reversed;
        this.reset();
    }

    startReset() {
        // Prevent reset from being called faster than paperjs can handle
        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
            this.timeout = null;
            this.reset();
        }, 50);
    }

    reset() {
        if (!this.configured_deferred) {
            this.configured_deferred = SimplePromise.defer();
            this.configured = this.configured_deferred.promise;
        }

        if (this.product_raster) {
            this.product_raster.remove();
            this.product_raster = null;
        }
        if (this.package_raster) {
            this.package_raster.remove();
            this.package_raster = null;
        }
        if (this.transfer_background) {
            this.transfer_background.remove();
            this.transfer_background = null;
        }
        if (this.transfer_layer) {
            this.transfer_layer.removeChildren();
        }

        this.configured.then(this.setupPackaging.bind(this));
        this.configured.then(this.setupProduct.bind(this));
    }

    override mounted() {
        super.mounted();
        this.setupPaper();
    }

    async setupPaper() {
        if (!this.configured_deferred) {
            this.configured_deferred = SimplePromise.defer();
            this.configured = this.configured_deferred.promise;
        }

        await this.paper_import;

        this.canvas = this.$el.querySelector('canvas');
        if (!this.canvas) {
            return
        }

        this.canvas_height = getInnerHeight(this.canvas);
        this.canvas_width = getInnerWidth(this.canvas);

        this.canvas.style.width = Number(this.canvas_width).toFixed(0) + 'px';
        this.canvas.style.height = Number(this.canvas_height).toFixed(0) + 'px';

        this.paperScope = new this.paper.PaperScope();
        this.paperScope.activate();
        this.paperScope.setup(this.canvas);
        this.paperScope.view.viewSize = new this.paper.Size(this.canvas_width, this.canvas_height);

        this.project = this.paperScope.project;
        this.layer = new this.paper.Layer();
        this.transfer_layer = new this.paper.Layer();
        this.layer.addChild(this.transfer_layer);

        this.layer.name = 'root';
        this.transfer_layer.name = 'transfer-layer';

        // Setup high quality down sampling for better scaling
        let ctx = this.paperScope.view.element.getContext('2d');
        ctx.imageSmoothingQuality = 'medium';
        ctx.imageSmoothingEnabled = true;

        this.configured_deferred.resolve(null);
    }

    get no_customer_preview() {
        if (!this.selectedPackage) {
            return false;
        }

        return this.selectedPackage.package.no_customer_preview;
    }

    get is_reversed() {
        if (!this.selectedPackage || !this.selectedPackage.package.content_back) {
            return false;
        }

        return this.reversed;
    }

    setupProduct() {
        if (this.no_customer_preview || this.is_reversed) {
            return;
        }

        // Setup product image background
        this.transfer_layer.visible = false;
        if (this.singleColor) {
            this.transfer_layer.removeChildren();

            let background_image = this.white_transfer_background;
            if (this.materialColor && this.materialColor.toLowerCase().indexOf('white') != -1) {
                background_image = this.blue_transfer_background;
            }

            this.transfer_background = new this.paper.Raster({
                source: background_image,
                crossOrigin: 'anonymous',
            });
            this.transfer_background.on('load', () => {
                this.transfer_background.fitBounds(new this.paper.Rectangle(0, 0, this.canvas_width, this.canvas_height));

                if (this.product_raster) {
                    this.product_raster.bringToFront();
                }
            })
            this.transfer_background.name = 'transfer-background';
            this.transfer_layer.addChild(this.transfer_background);
            this.transfer_layer.visible = false;
        }

        this.layer.activate();
        this.product_raster = new this.paper.Raster({
            source: this.productDesign,
            crossOrigin: 'anonymous',
        });
        this.product_raster.on('load', () => {
            if (this.selectedPackage) {
                // Setup Product Image
                let package_width = this.selectedPackage.size.package_width;
                let package_height = this.selectedPackage.size.package_height;

                let dpi;
                if (package_height > package_width) {
                    dpi = this.canvas_height / package_height;
                }
                else {
                    dpi = this.canvas_width / package_width;
                }

                let product_width = this.productWidth * dpi;
                let product_height = this.productHeight * dpi;

                let left_padding = (this.canvas_width / 2) - (product_width / 2);
                let top_padding = this.canvas_height - product_height - (0.05 * dpi); // Add 0.25" padding

                let bounds = new this.paper.Rectangle(
                    new this.paper.Point(left_padding, top_padding),
                    new this.paper.Size(product_width, product_height)
                )

                this.product_raster.fitBounds(bounds);

                if (this.transfer_background) {
                    let clipping = new this.paper.Path.Rectangle(bounds);
                    this.transfer_layer.addChild(clipping);

                    clipping.clipMask = true;

                    this.transfer_layer.visible = true;
                }

                this.product_raster.bringToFront();
            }
            else {
                let bounds = new this.paper.Rectangle(
                    new this.paper.Point(0, 0),
                    new this.paper.Size(this.canvas_width, this.canvas_height)
                )

                this.product_raster.fitBounds(bounds);
                this.product_raster.bringToFront();
            }
            this.product_raster.visible = true;
            this.product_raster.name = 'product-raster';
        });

        // Prevent flashing due to a loading bug/"feature" with paperjs
        this.product_raster.visible = false;

        this.layer.addChild(this.product_raster);
    }

    setupPackaging() {
        if (!this.selectedPackage) {
            return
        }

        this.layer.activate();
        this.package_raster = new this.paper.Raster({
            source: this.is_reversed ? this.selectedPackage.package.content_back : this.selectedPackage.package.content,
            crossOrigin: 'anonymous',
        });
        this.package_raster.on('load', () => {
            (this.package_raster as any).fitBounds(0, 0, this.canvas_width, this.canvas_height);
            this.package_raster.visible = true;
            this.package_raster.sendToBack();
        });
        this.package_raster.visible = false;
        this.package_raster.name = 'package-raster';

        this.layer.addChild(this.package_raster);
    }
}

export default function RetailPackageDisplay(): IVueComponent {
    return  {
        controller: RetailPackageDisplayController,
        tag: 'package-preview',
        template: html
    }
}
