

/*
    Only components that are used on the setup page
 */
import VueApp from '../../../../core/adapters/VueApp';
import SelectDesign from './SelectDesign/SelectDesign';
import UploadFile from './UploadFile/UploadFile';
import Price from '../../../products_shared/components/Price/Price';
import QuantityBreaks from '../../../products_shared/components/QuantityBreaks/QuantityBreaks';
import SelectFile from './SelectFile/SelectFile';
import SelectTemplate from './SelectTemplate/SelectTemplate';
import SelectAutosave from './SelectAutosave/SelectAutosave';
import ProductProductionTime from '../../../delivery/components/ProductProductionTime/ProductProductionTime';

export default function registerComponents(vue_app: VueApp) {
    vue_app.registerComponent(SelectDesign);
    vue_app.registerComponent(UploadFile);
    vue_app.registerComponent(Price);
    vue_app.registerComponent(QuantityBreaks);
    vue_app.registerComponent(SelectFile);
    vue_app.registerComponent(SelectTemplate);
    vue_app.registerComponent(SelectAutosave);
    vue_app.registerComponent(ProductProductionTime);
}